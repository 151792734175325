/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

const Map = (props) => {
 const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="w-full mt-[60px] mb-[70px]">
        <h2 className="text-center mb-2">We Cover {rpdata?.dbPrincipal?.miles}: </h2>
        <div className="flex flex-col md:flex-row w-full h-auto md:ml-2 md:mb-8 px-3" >
        <div className="md:w-[25%] w-full md:pr-4 md:my-0 my-3 ">
          <h4 className="text-center font-semibold md:text-[28px] text-2xl ColInc">{rpdata?.dbPrincipal?.location[1]?.address}</h4>
          <iframe src={rpdata?.dbPrincipal?.location?.[1].url} className="w-full h-[500px]"/>
        </div>
        <div className="md:w-[25%] w-full md:pr-4 md:my-0 my-3 ">
          <h4 className="text-center font-semibold md:text-[28px] text-2xl ColInc">{rpdata?.dbPrincipal?.location[2]?.address}</h4>
          <iframe src={rpdata?.dbPrincipal?.location?.[2].url} className="w-full h-[500px]"/>
        </div>
        <div className="md:w-[25%] w-full md:pr-4 md:my-0 my-3 ">
          <h4 className="text-center font-semibold md:text-[28px] text-2xl ColInc">{rpdata?.dbPrincipal?.location[3]?.address}</h4>
          <iframe src={rpdata?.dbPrincipal?.location?.[3].url} className="w-full h-[500px]"/>
        </div>
        <div className="md:w-[25%] w-full md:pr-4 md:my-0 my-3 ">
          <h4 className="text-center font-semibold md:text-[28px] text-2xl ColInc">{rpdata?.dbPrincipal?.location[4]?.address}</h4>
          <iframe src={rpdata?.dbPrincipal?.location?.[4].url} className="w-full h-[500px]"/>
        </div>
        </div>
      </div>
    </div>
  )
}

export default Map
